export const getCookieDomain = (url?: string): string => {
  const hostname = new URL(url || window.location.href).hostname;
  const RICOH360 = 'ricoh360.com';

  return hostname.includes(RICOH360) ? RICOH360 : hostname;
};

export const getCookieValue = (cookieName: string): string | null => {
  const match = document.cookie.match(
    new RegExp('(^| )' + cookieName + '=([^;]+)'),
  );
  return match ? match[2] : null;
};

export const setCookie = (
  name: string,
  value: string,
  domain: string,
  maxAge = 31536000,
) => {
  document.cookie = `${name}=; domain=${domain}; path=/; max-age=0`;
  document.cookie = `${name}=${value}; domain=${domain}; path=/; max-age=${maxAge}`;
};
