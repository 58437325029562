import './src/styles/global.css';
import type { GatsbyBrowser } from 'gatsby';
import CustomLayout from './gatsby/wrapPageElement';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = CustomLayout;

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({
  location: { search },
}) => {
  const ref = new URLSearchParams(search).get('ref');
  if (ref) {
    document.querySelectorAll('a').forEach((item) => {
      if (item.href) {
        const url = new URL(item.href);
        if (
          url.hostname.match(/^(?=.*(ricoh360.com|ricohprojects.com)).*$/) ||
          url.hostname === 'localhost' ||
          !url.origin
        ) {
          const urlParamRef = url.searchParams.get('ref');
          if (!urlParamRef) {
            url.searchParams.set('ref', ref);
            // @ts-expect-error TS2322: Type 'string' is not assignable to type 'never'.
            item.href = url;
          }
        }
      }
    });
  }
  return false; // ref: https://github.com/gatsbyjs/gatsby/issues/3249#issuecomment-360868222
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
  prevRouterProps,
  routerProps,
}) =>
  prevRouterProps?.location.pathname.replace(/\/$/, '') !==
  routerProps.location.pathname.replace(/\/$/, '');

/**
 * Delete unnecessary cookies such as "www.ricoh360.com" and "staging.ricoh360.com"
 * the following code can be deleted in the future.
 */
(() => {
  window.document.cookie = `locale=; path=/; domain=www.ricoh360.com; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  window.document.cookie = `nf_lang=; path=/; domain=www.ricoh360.com; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  window.document.cookie = `locale=; path=/; domain=staging.ricoh360.com; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  window.document.cookie = `nf_lang=; path=/; domain=staging.ricoh360.com; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
})();
