export const defaultLanguage = 'en';
export const languages = ['en', 'ja', 'fr', 'es'];

export const defaultCountry = 'ww';
export const regions = ['ww', 'us', 'eu', 'jp'] as const;

export const rewrites: { [key: string]: string } = {
  eu: 'ad,at,ax,be,bl,cy,de,ee,es,fi,fr,gb,gf,gp,gr,ie,it,lu,lv,mc,mf,mq,mt,nl,pm,pt,re,si,sk,sm,tf,va,yt',
  us: 'ca,mx,us',
  jp: 'jp',
};
