import type { GatsbySSR } from 'gatsby';
import smoothscroll from 'smoothscroll-polyfill';
import { DevPanel } from '../src/components/DevPanel';
import LocaleProvider from '../src/providers/LocaleProvider';
import PageContextProvider from '../src/providers/PageContextProvider';

if (typeof window !== 'undefined') {
  smoothscroll.polyfill();
}

// Pass all props (hence the ...props) to the layout component so it has access to things like pageContext or location
const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }) => (
  <PageContextProvider {...props}>
    <LocaleProvider {...props}>{element}</LocaleProvider>
    <DevPanel />
  </PageContextProvider>
);

export default wrapPageElement;
