import { type ReactNode, createContext } from 'react';

type Context = {
  title?: string;
  description?: string;
  canonical?: string;
  theme?: string;
  siteURL?: string;
};

type Props = {
  pageContext: Context;
  children: ReactNode;
};

export const PageContext = createContext<Context>({});

const PageContextProvider = ({ children, pageContext }: Props) => (
  <PageContext.Provider value={pageContext}>{children}</PageContext.Provider>
);

export default PageContextProvider;
