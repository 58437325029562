import { useEffect, useState } from 'react';
import { getCookieValue } from '@/utils/cookie';
import { rewrites, regions } from '~/config/i18n';

type Region = (typeof regions)[number];

export const useUserRegion = (): Region => {
  const [region, setRegion] = useState<Region>('ww');

  useEffect(() => {
    const overwriteCountryCode =
      (process.env.COUNTRY as Region) ||
      (sessionStorage.getItem('country-code-overwrite') as Region);
    if (overwriteCountryCode && regions.includes(overwriteCountryCode))
      return setRegion(overwriteCountryCode);

    const country = getCookieValue('country-code')?.toLowerCase();
    if (!country) return;

    for (const region of Object.keys(rewrites) as Region[]) {
      if (rewrites[region].includes(country) && regions.includes(region))
        setRegion(region);
    }
  }, []);

  return region;
};
