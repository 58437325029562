import { type ReactNode, createContext } from 'react';
import {
  regions as defaultRegionList,
  defaultCountry,
  defaultLanguage,
  languages as defaultLanguageList,
} from '../../config/i18n';

type Context = {
  languages: string[];
  language: string;
  defaultLanguage: string;
  countries: string[];
  country: string;
  defaultCountry: string;
};

type Props = {
  children: ReactNode;
  pageContext: {
    languages?: string[];
    language?: string;
    countries?: string[];
    country?: string;
  };
};

export const LocaleContext = createContext<Context>({
  languages: [],
  language: '',
  defaultLanguage,
  countries: [],
  country: '',
  defaultCountry,
});

const LocaleProvider = ({
  children,
  pageContext: {
    languages = defaultLanguageList,
    language = defaultLanguage,
    // FIXME: solve this error
    // @ts-expect-error const
    countries = defaultRegionList,
    country = defaultCountry,
  },
}: Props) => (
  <LocaleContext.Provider
    value={{
      languages,
      language,
      defaultLanguage,
      countries: countries as string[],
      country,
      defaultCountry,
    }}
  >
    {children}
  </LocaleContext.Provider>
);

export default LocaleProvider;
