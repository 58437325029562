import { type ChangeEvent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useUserRegion } from '@/hooks/useUserRegion';
import { getCookieDomain, getCookieValue, setCookie } from '@/utils/cookie';

export const DevPanel = () => {
  const [isDev, setIsDev] = useState(false);
  const [showPanel, setShowPanel] = useState(true);
  const [locale, setLocale] = useState('default');

  const userRegion = useUserRegion();

  useEffect(() => {
    setIsDev(
      // localhost
      window.location.href.includes('localhost') ||
        // staging preview
        !!window?.location?.href?.match(/staging.netlify.app/g) ||
        // staging
        !!window?.location?.href?.match(/staging.ricoh360.com/g) ||
        // production preview
        !!window?.location?.href?.match(/goofy-mahavira-9d906d.netlify.app/g),
    );

    setLocale(getCookieValue('locale') || 'default');
  }, []);

  const onLocaleChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    const newLocale = e.target.value;
    setCookie('locale', newLocale, getCookieDomain());
    setLocale(newLocale);
  };

  const onRegionChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    const newCountry = e.target.value;
    sessionStorage.setItem('country-code-overwrite', newCountry);
  };

  if (isDev) {
    return createPortal(
      <div
        className={`fixed top-1/2 right-0 z-50 p-5 pl-10 bg-slate-50 bg-opacity-80 rounded-l-lg shadow-xl transform transition duration-300 ease-in-out ${showPanel ? 'translate-x-0' : 'translate-x-[calc(100%-40px)]'}`}
      >
        <div className="flex mb-2">
          <button
            className="mr-4 cursor-pointer"
            onClick={() => window.scrollTo(0, 0)}
          >
            👆️
          </button>
          <button
            className="cursor-pointer"
            onClick={() =>
              window.scrollTo(0, window.document.body.offsetHeight)
            }
          >
            👇
          </button>
        </div>
        <div>
          <div className="mb-2">
            <span className="block">Locale</span>
            <select
              className="p-1 bg-white bg-opacity-50 border border-gray-300"
              value={locale}
              onChange={onLocaleChanged}
            >
              <option value={'default'}>--</option>
              <option value="en">English</option>
              <option value="ja">Japanese</option>
              <option value="fr">French</option>
              <option value="es">Spanish</option>
            </select>
          </div>
          <div className={'selectors'}>
            <span className="block">Region</span>
            <select
              className="p-1 bg-white bg-opacity-50 border border-gray-300"
              defaultValue={userRegion}
              onChange={onRegionChanged}
            >
              <option value={'default'}>--</option>
              <option value="us">United States</option>
              <option value="jp">Japan</option>
              <option value="eu">Europe</option>
              <option value="ww">Other</option>
            </select>
          </div>
        </div>

        <div className="flex flex-col">
          <button
            className="px-4 py-2 mt-4 mb-2 text-white bg-gray-600 rounded-md text-sm"
            onClick={() => window.location.reload()}
          >
            Update
          </button>
          <button
            className="px-4 py-2 text-red-600 bg-white border border-red-600 rounded-md text-sm"
            onClick={() => setIsDev(false)}
          >
            Delete dev panel
          </button>
        </div>

        <button
          className="absolute top-1/2 left-0 p-2 cursor-pointer transform -translate-y-1/2"
          data-testid="devpanel-toggle"
          onClick={() => setShowPanel(!showPanel)}
        >
          {showPanel ? '>>' : '<<'}
        </button>
      </div>,
      document.body,
    );
  }

  return null;
};
